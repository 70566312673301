import React from 'react'
import "../styles/about.css"
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

function About (props) {
const {image, pdf, teachingPdf, researchPdf, body} = props

return (

  <div className="wrapper">
    <h3>{props.title}</h3>
    <div id="about-holder" class="grid about">
      <div className="width-12-12-m width-6-12">

        {props.image && image.asset && (
          <div className="image-holder">
            <figure
                  style={{
                    backgroundImage: `url(${props.image.asset.metadata.lqip})`,
                    paddingTop: `calc(100% / ${props.image.asset.metadata.dimensions.aspectRatio})`
                   }}
                >
                  <img
                    src={imageUrlFor(buildImageObj(props.image))
                      .width(1200)
                      .auto('format')
                      .url()}
                    alt={props.image.alt}
                    title={props.image.alt}
                  />
                </figure>
            {image.caption && (
              <span className="caption">
                {image.caption}
              </span>
            )}

          </div>
        )}

      </div>
      <div className="width-12-12-m width-6-12 page">
        {pdf && teachingPdf && researchPdf &&
          <div className="pdf">
          {pdf && pdf.file && 
              <p className="extras"><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
          }
          {teachingPdf && teachingPdf.file && 
              <p className="extras"><a href={teachingPdf.file.asset.url} rel="noopener noreferrer" target="_blank">{teachingPdf.title} (pdf)</a></p>
          }
          {researchPdf && researchPdf.file && 
              <p className="extras"><a href={researchPdf.file.asset.url} rel="noopener noreferrer" target="_blank">{researchPdf.title} (pdf)</a></p>
          }
          </div>
        }     

        {body && (
            <div className="body-holder">
                {body && <PortableText blocks={body} />}
              </div>
            )}
      </div>

    </div>
  </div>

)}

export default About